var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.clientSidepanelStatus ? _c('ClientSidepanel', {
    on: {
      "updated": function ($event) {
        return _vm.refresh(true);
      }
    }
  }) : _vm._e(), _vm.modalDeals ? _c('Modal', {
    attrs: {
      "customerId": _vm.$route.params.id
    }
  }) : _vm._e(), _c('Header', {
    attrs: {
      "clear-filters": _vm.clearFilters,
      "loaded": _vm.loaded,
      "contact-filters": _vm.contactFilters
    },
    on: {
      "updated": function ($event) {
        _vm.customerUpdated++;
      },
      "openClientPanel": function ($event) {
        return _vm.setClientSidepanel(true);
      }
    }
  }), _vm.totalPages > 0 ? _c('PaginationNumbers', {
    key: _vm.customerUpdated + _vm.organizationUpdateKey,
    staticClass: "pagination-numbers",
    attrs: {
      "total-pages": _vm.totalPages,
      "total": _vm.totalCount,
      "per-page": _vm.limit,
      "current-page": _vm.currentPage
    },
    on: {
      "pagechanged": _vm.onPageChange
    }
  }) : _vm._e(), _c('Content', {
    attrs: {
      "organizations": _vm.organizations,
      "updated": _vm.customerUpdated,
      "organizationUpdate": _vm.organizationUpdateKey,
      "loaded": _vm.loaded,
      "selectedSort": _vm.selectedSort
    },
    on: {
      "clearFilters": function () {
        return _vm.clearFilters++;
      },
      "sortCustomers": _vm.sortData,
      "sortOrganizations": _vm.sortData
    }
  }), _vm.totalPages > 0 ? _c('PaginationNumbers', {
    key: _vm.customerUpdated + _vm.organizationUpdateKey,
    staticClass: "pagination-numbers",
    attrs: {
      "total-pages": _vm.totalPages,
      "total": _vm.totalCount,
      "per-page": _vm.limit,
      "current-page": _vm.currentPage
    },
    on: {
      "pagechanged": _vm.onPageChange
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }