var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content",
    attrs: {
      "id": "contact-content"
    }
  }, [!_vm.loaded ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "24px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  }) : _c('div', {
    key: _vm.activeContactsTab + 1
  }, [_vm.showNoResultsFound ? _c('div', {
    staticClass: "no-results-wrapper"
  }, [_c('p', {
    staticClass: "heading-geo32 no-results"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoResultsFound")) + " ")]), _c('p', {
    staticClass: "clear-filters-button",
    on: {
      "click": _vm.removeFiltersContacts
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ClearAllFilters")) + " ")])]) : false ? _c('div', {
    staticClass: "no-results-wrapper"
  }, [_c('p', {
    staticClass: "heading-geo32 no-results"
  }, [_vm._v(" " + _vm._s(_vm.$t("AddAContactToStart")) + " ")])]) : _vm._e(), _vm.activeContactsTab !== 'organizations' && !_vm.showNoResultsFound ? _c('Table', {
    attrs: {
      "fields": _vm.tableFields,
      "dataset": _vm.filteredCustomers,
      "add-gap": true,
      "customer-view": true,
      "update-key": _vm.updatedCustomer,
      "sort-again": _vm.sortUpdater,
      "selected-sort": _vm.selectedSort
    },
    on: {
      "sortCustomers": _vm.sortCustomers
    }
  }) : _vm._e(), _vm.activeContactsTab === 'organizations' && !_vm.showNoResultsFound ? _c('Table', {
    attrs: {
      "fields": _vm.organizationsTableFields,
      "dataset": _vm.organizations,
      "update-key": _vm.organizationsUpdater,
      "organizations-view": true,
      "selected-sort": _vm.selectedSort
    },
    on: {
      "fieldOnPressed": _vm.openOrganizationSidepanel,
      "sortOrganizations": _vm.sortOrganizations
    }
  }) : _vm._e()], 1), _vm.selectedOrganization ? _c('OrganizationSidepanel', {
    attrs: {
      "selected-organization": _vm.selectedOrganization
    },
    on: {
      "panelClosed": function ($event) {
        _vm.selectedOrganization = null;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }