<template>
  <div>
    <ClientSidepanel
      v-if="clientSidepanelStatus"
      @updated="refresh(true)"
    />
    <Modal v-if="modalDeals" :customerId="$route.params.id" />
    <Header
      :clear-filters="clearFilters"
      :loaded="loaded"
      @updated="customerUpdated++"
      :contact-filters="contactFilters"
      @openClientPanel="setClientSidepanel(true)"
    />
    <PaginationNumbers
      :key="customerUpdated + organizationUpdateKey"
      v-if="totalPages > 0"
      class="pagination-numbers"
      :total-pages="totalPages"
      :total="totalCount"
      :per-page="limit"
      :current-page="currentPage"
      @pagechanged="onPageChange"
    />
    <Content
      :organizations="organizations"
      :updated="customerUpdated"
      :organizationUpdate="organizationUpdateKey"
      :loaded="loaded"
      :selectedSort="selectedSort"
      @clearFilters="() => clearFilters++"
      @sortCustomers="sortData"
      @sortOrganizations="sortData"
    />
    <PaginationNumbers
      :key="customerUpdated + organizationUpdateKey"
      v-if="totalPages > 0"
      class="pagination-numbers"
      :total-pages="totalPages"
      :total="totalCount"
      :per-page="limit"
      :current-page="currentPage"
      @pagechanged="onPageChange"
    />
  </div>
</template>

<script>
import Header from "../../components/Kliendiregister/Header.vue";
import Content from "../../components/Kliendiregister/Content.vue";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel.vue";
import Modal from "../../components/Kliendiregister/CustomerDetails/Modal.vue";
import PaginationNumbers from "@/components/common/PaginationNumbers.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Kliendiregister",
  components: {
    Header,
    Content,
    ClientSidepanel,
    Modal,
    PaginationNumbers,
  },
  data() {
    return {
      clearFilters: 0,
      customer: null,
      customerUpdated: 0,
      contactFilters: null,
      currentPage: 1,
      limit: 50,
      totalCount: 0,
      customers: [],
      organizations: null,
      organizationUpdateKey: 0,
      loaded: false,
      isFirstRender: false,
      selectedSort: {
        sortField: null,
        sortOrder: null,
      }
    };
  },
  async created() {
    await this.changeModalStateDeals(0);
    if (!this.$route.params.id) {
      await this.setClientSidepanel(0);
      // window.history.replaceState({}, document.title, "/contacts?page=1");
    }
  },
  mounted() {
    if (this.$route.query.page) this.currentPage = parseInt(this.$route.query.page)
    this.refresh(true)
  },
  computed: {
    ...mapGetters([
      "clientSidepanelStatus",
      "modalDeals",
      "provisionalFilters",
      "provisionalFiltersUpdater",
      "activeContactsTab",
      "customersCount",
      "language",
      "filters",
        "user"
    ]),
    totalPages() {
      return Math.ceil(this.totalCount / this.limit);
    }
  },
  watch: {
    async provisionalFiltersUpdater() {
      if (!this.isFirstRender) {
        this.isFirstRender = true
        return
      }
      await this.refresh(false);
    },
    async activeContactsTab() {
      const filterSelected = this.filters.contacts.find((filter) => filter.isSelected);
      if (filterSelected._id === "1") {
        this.provisionalFilters.contacts.find((filter) => filter.name === "brokerId").value = this.user._id
      }
      await this.refresh(false);
    },
  },
  methods: {
    ...mapActions([
      "setClientSidepanel",
      "getCustomersByPagination",
      "changeModalStateDeals",
    ]),
    async refresh(isLanding) {
      this.loaded = false

      if (!isLanding) {
        this.currentPage = 1;
        await this.$router.push({
          name: "Kliendiregister",
          query: {
            page: this.currentPage,
          },
        });
      }

      if (this.activeContactsTab === "organizations") {
        await this.initOrganizations();
      } else {
        await this.initCustomers();
      }
      this.loaded = true
    },
    setupPageData() {
      let conditions = this.provisionalFilters.contacts;
      if (
        !this.provisionalFilters["contacts"].find(
          (condition) => condition.name === "createdAt")
      ) {
        this.provisionalFilters["contacts"].push({
          name: "createdAt",
          title: "CreatedAt",
          value: "",
          valueTitle: "",
        },
       )
      }
      if (
        !this.provisionalFilters["contacts"].find(
          (condition) => condition.name === "createdFrom")
      ) {
        this.provisionalFilters["contacts"].push({
          name: "createdFrom",
          title: "createdFrom",
          value: "",
          valueTitle: "",
        }, 
        {
          name: "createdTo",
          title: "createdTo",
          value: "",
          valueTitle: "",
        })
      }
      let conditionsObj = conditions.reduce(
          (a, v) => ({ ...a, [v.name]: v.value }),
          {}
      );

      return {
        lang: this.language,
        page: this.$route.query.page,
        filters: conditionsObj,
        limit: this.limit,
        sortField: this.selectedSort.sortField,
        sortOrder: this.selectedSort.sortOrder,
      };
    },
    async sortData(event) {
      this.selectedSort = {
        sortField: event.sortField,
        sortOrder: event.sortOrder
      }

      await this.refresh(true)
    },
    async initOrganizations() {
      const pageData = this.setupPageData();

      const res = await axios.post(`/api/organizations-pagination/${pageData.page}`, pageData);
      this.organizations = res.data.organizations;
      this.totalCount = res.data.totalCount;
      this.organizationUpdateKey++;
    },
    async initCustomers() {
      const pageData = this.setupPageData();
      pageData.filters.activeTab = this.activeContactsTab;
      if (this.activeContactsTab === 'partners') {
        pageData.filters.estate = [];
        pageData.filters.ownership = [];
        pageData.filters.transaction = [];
        pageData.filters.brokerId = '';
      }
      this.contactFilters = pageData.filters
      await this.getCustomersByPagination(pageData);
      this.totalCount = this.customersCount;
      this.customerUpdated++;
    },
    async onPageChange(page, notificationId) {
      await this.$router.push({
        name: "Kliendiregister",
        query: {
          page: page,
          notificationId: notificationId,
        },
      });
      this.currentPage = page;
      window.scroll(0, 0);
      await this.refresh(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-numbers {
  margin-left: 679px;
  width: 300px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  height: 64px;
  color: black;
}
</style>
