<template>
  <div class="content" id="contact-content">
    <div
        v-if="!loaded"
        style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
        class="loader"
    />
    <div v-else :key="activeContactsTab + 1">
      <div v-if="showNoResultsFound"
          class="no-results-wrapper"
      >
        <p class="heading-geo32 no-results">
          {{ $t("NoResultsFound") }}
        </p>
        <p class="clear-filters-button" @click="removeFiltersContacts">
          {{ $t("ClearAllFilters") }}
        </p>
      </div>
      <div v-else-if="false"
          class="no-results-wrapper"
      >
        <p class="heading-geo32 no-results">
          {{ $t("AddAContactToStart") }}
        </p>
      </div>

      <Table
        v-if="activeContactsTab !== 'organizations' && !showNoResultsFound"
        :fields="tableFields"
        :dataset="filteredCustomers"
        :add-gap="true"
        :customer-view="true"
        :update-key="updatedCustomer"
        :sort-again="sortUpdater"
        :selected-sort="selectedSort"
        @sortCustomers="sortCustomers"
      />
      <Table
        v-if="activeContactsTab === 'organizations' && !showNoResultsFound"
        :fields="organizationsTableFields"
        :dataset="organizations"
        :update-key="organizationsUpdater"
        :organizations-view="true"
        :selected-sort="selectedSort"
        @fieldOnPressed="openOrganizationSidepanel"
        @sortOrganizations="sortOrganizations"
      />
    </div>
    <OrganizationSidepanel
        v-if="selectedOrganization"
        :selected-organization="selectedOrganization"
        @panelClosed="selectedOrganization = null"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Table from "@/components/common/Table";
import OrganizationSidepanel from "@/components/Organization/OrganizationSidepanel";
export default {
  name: "Content",
  props: ["updated", "organizations", "organizationUpdate", "loaded", "selectedSort"],
  components: {OrganizationSidepanel, Table },
  data() {
    return {
      selectedOrganization: null,
      sortUpdater: 0,
      updatedCustomer: 0,
      tableFields: [
        {
          fieldValue: this.$t("FirstName"),
          jsonValue: "firstName",
          style: "min-width:160px; max-width:160px; height:60px",
        },
        {
          fieldValue: this.$t("LastName"),
          jsonValue: "lastName",
          style: "min-width: 160px; max-width: 160px; height:60px;",
        },
        {
          fieldValue: this.$t("PhoneNumber"),
          jsonValue: "phoneNumber",
          style: "min-width: 180px; max-width: 180px; height:60px",
        },
        {
          fieldValue: this.$t("Email"),
          jsonValue: "email",
          style: "min-width: 200px; max-width: 200px; height:60px",
        },
        {
          fieldValue: this.$t("OrganizationsName"),
          jsonValue: "organization",
          style: "min-width: 204px; max-width: 204px; height:60px",
        },
        {
          fieldValue: this.$t("BirthdayDate"),
          jsonValue: "birthday",
          style: "min-width: 86px; max-width: 86px; height:60px",
        },
        {
          fieldValue: this.$t("Created"),
          jsonValue: "createdAt",
          style:"min-width: 132px; max-width: 132px; height:60px",
        },
        {
          fieldValue: this.$t("Tags"),
          jsonValue: "tags",
          style: "min-width: 160px; max-width: 160px; height:60px",
        },
        {
          fieldValue: this.$t("Interests"),
          jsonValue: "interests",
          style: "min-width: 86px; max-width: 86px; height:60px",
        },
        {
          fieldValue: this.$t("Projects"),
          jsonValue: "projects",
          style: "min-width: 86px; max-width: 86px; height:60px",
        },
        {
          fieldValue: "",
          jsonValue: "files",
          style:
              "min-width: 113px; max-width: 113px; height:60px;padding-left:64px",
        }
      ],
      organizationsTableFields: [
        {
          fieldValue: this.$t("Organization"),
          jsonValue: "organization",
          style: "min-width:240px; max-width:240px; height:60px",
        },
        {
          fieldValue: this.$t("Email"),
          jsonValue: "email",
          style: "min-width: 200px; max-width: 200px; height:60px;",
        },
        {
          fieldValue: this.$t("Address"),
          jsonValue: "address",
          style: "min-width: 400px; max-width: 400px; height:60px",
        },
        {
          fieldValue: this.$t("Contacts"),
          jsonValue: "relatedContacts",
          style: "min-width: 96px; max-width: 96px; height:60px",
        },
        {
          fieldValue: this.$t("Invoices"),
          jsonValue: "relatedInvoices",
          style: "min-width: 96px; max-width: 96px; height:60px",
        },
        {
          fieldValue: this.$t("Projects"),
          jsonValue: "relatedProjects",
          style: "min-width: 96px; max-width: 96px; height:60px",
        },
      ],
      customer: null,
      sectorTypes: ["All", "Juridical persons", "Private persons"],
      chosenSectorType: "All",
      chosenCustomerTypes: null,
      emptyArr: [],
      veryOriginalArr: [],
      filteredCustomers: [],
      customerTypes: [
        "Buyer",
        "Seller",
        "Landlord",
        "Tenant",
        "Looking to buy",
        "Looking to rent",
        "Appraisement",
        "Business appraisement",
        "Partner",
        "Broker partner",
      ],
      organizationsUpdater: 0
    };
  },
  created() {
    this.veryOriginalArr = [...this.customersPagination];
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "customersPagination",
      "partners",
      "customersUpdaterKey",
      "partnersUpdaterKey",
      "provisionalFiltersUpdater",
      "provisionalFilters",
      "filters",
      "clientSidepanelStatus",
      "activeContactsTab",
      "activeContactsTabUpdater",
    ]),
    showNoResultsFound() {
      if (this.activeContactsTab === "organizations") {
        return !this.organizations || this.organizations.length === 0
      }
      return !this.filteredCustomers || this.filteredCustomers.length === 0
    },
    originalArr() {
      return this.customersPagination;
    },
  },
  watch: {
    organizationUpdate() {
      this.organizationsUpdater++;
    },
    originalArr() {
      this.filteredCustomers = this.originalArr;
      this.updatedCustomer++;
      setTimeout(() => {
        this.setupTags();
      }, 1500);
      this.setupTags();
    },
    // provisionalFiltersUpdater() {
    //   const conditions = this.provisionalFilters["contacts"];
    //   this.filterByConditions(conditions);
    // },
    customersUpdaterKey() {
      this.updatedCustomer++;
    },

    updated: async function () {
      this.veryOriginalArr = [...this.customersPagination];

      // if (this.activeContactsTab === "contacts") {
      //   // await this.getCustomers();
      //   this.veryOriginalArr = [...this.customersPagination];
      // } else if (this.activeContactsTab === "partners") {
      //   await this.getPartners();
      //   this.veryOriginalArr = [...this.partners];
      // }
      this.filteredCustomers = this.originalArr;
      this.setupTags();
      this.updatedCustomer++;
      const conditions = this.provisionalFilters["contacts"];

      if (this.activeContactsTab === 'partners') {
        conditions.find((condition) => condition.name === "brokerId").value = ''
        conditions.find((condition) => condition.name === "ownership").value = []
        conditions.find((condition) => condition.name === "estate").value = []
        conditions.find((condition) => condition.name === "transaction").value = []
      }

      // this.filterByConditions(conditions);
      this.sortUpdater++;
    },
  },
  methods: {
    ...mapActions([
      // "getCustomers",
      "getPartners",
      "changeClientAddModal",
      "setClientSidepanel",
      "setProvisionalFilter",
      "changeProvisionalFilterCondition",
      "setActiveContactsTab",
    ]),
    openOrganizationSidepanel(organizationId) {
      this.selectedOrganization = organizationId
    },
    sortCustomers(data) {
      this.$emit("sortCustomers", data);
    },
    sortOrganizations(data) {
      this.$emit("sortOrganizations", data);
    },
    filterByConditions(conditions) {
      let contacts = JSON.parse(JSON.stringify(this.originalArr));

      conditions.forEach((condition) => {
        switch (condition.name) {
          case "search":
            contacts = this.searchFunction(contacts, condition.value);
            break;
          case "tag":
            contacts = this.filterTagsFunction(contacts, condition.value);
            break;
          case "transaction":
            contacts = this.filterTransactionsFunction(
              contacts,
              condition.value
            );
            break;
          case "estate":
            contacts = this.filterEstatesFunction(contacts, condition.value);
            break;
          case "ownership":
            contacts = this.filterOwnershipsFunction(contacts, condition.value);
            break;
          case "brokerId":
            contacts = this.searchByBroker(contacts, condition.value);
        }
      });
      this.filteredCustomers = contacts;
      this.updatedCustomer++;
      this.sortUpdater++;
    },
    removeFiltersContacts() {
      const some = JSON.parse(
        JSON.stringify(this.filters["contacts"][0].conditions)
      );
      this.$emit("clearFilters");
      this.setProvisionalFilter({
        filterName: "contacts",
        conditions: some,
      });
    },
    combineFilters(searhCustomers, tagCustomers) {
      let combined = [];
      searhCustomers.forEach((el) => {
        tagCustomers.forEach((elem) => {
          if (el.customerId === elem.customerId) {
            combined.push(el);
          }
        });
      });
      return combined;
    },
    setupTags() {
      this.veryOriginalArr.forEach((el) => {
        if (el.tags) {
          for (const [key, value] of Object.entries(el.tags)) {
            if (typeof value !== "boolean") return;
            if (key === "buyer") {
              el.tags[key] = {
                active: value,
                priority: 3,
              };
            }
            if (key === "landlord") {
              el.tags[key] = {
                active: value,
                priority: 4,
              };
            }
            if (key === "other") {
              el.tags[key] = {
                active: value,
                priority: 1,
              };
            }
            if (key === "propertyOwner") {
              el.tags[key] = {
                active: value,
                priority: 5,
              };
            }
            if (key === "tenant") {
              el.tags[key] = {
                active: value,
                priority: 2,
              };
            }
            if (key === "photographer") {
              el.tags[key] = {
                active: value,
                priority: 6,
              };
            }
            if (key === "valuator") {
              el.tags[key] = {
                active: value,
                priority: 7,
              };
            }
            if (key === "notary") {
              el.tags[key] = {
                active: value,
                priority: 8,
              };
            }
            if (key === "bank_representative") {
              el.tags[key] = {
                active: value,
                priority: 9,
              };
            }
            if (key === "otherInPartner") {
              el.tags[key] = {
                active: value,
                priority: 10,
              };
            }
          }
        }
      });
    },
    filteredCustomerFunction() {
      const customers = this.originalArr;

      let tagCustomers;
      let searchCustomers;
      for (const [key, value] of Object.entries(
        this.provisionalFilters["contacts"]
      )) {
        switch (key) {
          case "search":
            searchCustomers = this.searchFunction(customers, value);
            break;
          case "tag":
            tagCustomers = this.filterTagsFunction(customers, value);
            break;
        }
      }

      if (
        searchCustomers.length > 0 &&
        searchCustomers.length !== this.originalArr.length &&
        tagCustomers.length === 0
      ) {
        this.filteredCustomers = [];
      } else if (
        searchCustomers.length === this.originalArr.length &&
        tagCustomers.length === 0
      ) {
        this.filteredCustomers = [];
      } else if (searchCustomers.length === 0 && tagCustomers.length > 0) {
        this.filteredCustomers = [];
      } else if (searchCustomers.length > 0 && tagCustomers.length > 0) {
        const combined = this.combineFilters(searchCustomers, tagCustomers);
        this.filteredCustomers = combined;
      }
      this.updatedCustomer++;
      this.sortUpdater++;
    },
    filterTagsFunction(originalArr, value) {
      let filterValue = value;
      let emptyArr = [];
      if (filterValue === "show_all") {
        return originalArr;
      } else if (filterValue === "tenant") {
        let arr = [];
        originalArr.forEach((el) => {
          if (el.tags.tenant["active"]) {
            arr.push(el);
          }
        });
        return arr;
      } else if (filterValue === "other") {
        emptyArr = [];
        originalArr.forEach((el) => {
          if (el.tags.other["active"]) {
            emptyArr.push(el);
          }
        });
        return emptyArr;
      } else if (filterValue === "property_owner") {
        emptyArr = [];
        originalArr.forEach((el) => {
          if (el.tags.propertyOwner["active"]) {
            emptyArr.push(el);
          }
        });
        return emptyArr;
      } else if (filterValue === "buyer") {
        emptyArr = [];
        originalArr.forEach((el) => {
          if (el.tags.buyer["active"]) {
            emptyArr.push(el);
          }
        });
        return emptyArr;
      } else if (filterValue === "landlord") {
        emptyArr = [];
        originalArr.forEach((el) => {
          if (el.tags.landlord["active"]) {
            emptyArr.push(el);
          }
        });
        return emptyArr;
      } else if (filterValue === "photographer") {
        emptyArr = [];
        originalArr.forEach((el) => {
          if (el.tags.photographer["active"]) {
            emptyArr.push(el);
          }
        });
        return emptyArr;
      } else if (filterValue === "valuator") {
        emptyArr = [];
        originalArr.forEach((el) => {
          if (el.tags.valuator["active"]) {
            emptyArr.push(el);
          }
        });
        return emptyArr;
      } else if (filterValue === "notary") {
        emptyArr = [];
        originalArr.forEach((el) => {
          if (el.tags.notary["active"]) {
            emptyArr.push(el);
          }
        });
        return emptyArr;
      } else if (filterValue === "bank_representative") {
        emptyArr = [];
        originalArr.forEach((el) => {
          if (el.tags.bank_representative["active"]) {
            emptyArr.push(el);
          }
        });
        return emptyArr;
      }
    },
    filterTransactionsFunction(originalArr, value) {
      let filterValuesArr = [];
      let customersArr = [];
      if (value !== [] && value.length !== 0) {
        value.forEach((val) => {
          filterValuesArr.push(val);
        });
        filterValuesArr.forEach((filterValue) => {
          originalArr.forEach((person) => {
            if (person.interests.length > 0) {
              person.interests.forEach((interest) => {
                if (
                  interest.transactionType === filterValue &&
                  !customersArr.includes(person)
                ) {
                  customersArr.push(person);
                }
              });
            }
          });
        });
        return customersArr;
      } else {
        return originalArr;
      }
    },
    filterEstatesFunction(originalArr, value) {
      let filterValuesArr = [];
      let customersArr = [];

      if (value !== [] && value.length !== 0) {
        value.forEach((val) => {
          filterValuesArr.push(val);
        });
        filterValuesArr.forEach((filterValue) => {
          originalArr.forEach((person) => {
            if (person.interests.length > 0) {
              person.interests.forEach((interest) => {
                if (
                  interest.estateType === filterValue &&
                  !customersArr.includes(person)
                ) {
                  customersArr.push(person);
                }
              });
            }
          });
        });
        return customersArr;
      } else {
        return originalArr;
      }
    },
    filterOwnershipsFunction(originalArr, value) {
      let filterValuesArr = [];
      let customersArr = [];

      if (value !== [] && value.length !== 0) {
        value.forEach((val) => {
          filterValuesArr.push(val);
        });
        filterValuesArr.forEach((filterValue) => {
          originalArr.forEach((person) => {
            if (
              person.clientOwnership === filterValue &&
              !customersArr.includes(person)
            ) {
              customersArr.push(person);
            }
          });
        });
        return customersArr;
      } else {
        return originalArr;
      }
    },
    searchByBroker(fullArray, value) {
      const arrayFilteredByBroker = [];
      if (value) {
        fullArray.forEach((item) => {
          if (item.creator.userId === value) {
            arrayFilteredByBroker.push(item);
          } else if (item.sharedCreators) {
              item.sharedCreators.forEach((sharedCreator) => {
                if (sharedCreator.userId.includes(value)) {
                  arrayFilteredByBroker.push(item)
                }
            })
          }
        });
        return arrayFilteredByBroker;
      } else {
        return fullArray;
      }
    },

    searchFunction(originalArr, value) {
      if (!originalArr) {
        originalArr = this.originalArr;
      }
      let searchValue = value.toLowerCase();
      let rightEmails = [];
      let rightPhoneNumbers = [];
      let rightOrganizations = [];
      let tempItemsContactsEmails = originalArr.reverse();
      let tempItemsContactsOrganizations = originalArr.reverse();
      let tempItemsContactsPhoneNumbers = originalArr.reverse();
      let tempItemsCustomerNames = originalArr.reverse();
      let filteredItems = null;
      let uniqueValuesSet = new Set();
      let filteredArray = [];
      if (searchValue) {
        tempItemsContactsEmails = tempItemsContactsEmails.map((element) => {
          return {
            ...element,
            emails: element.emails.filter(
              (item) => item.email.toLowerCase().indexOf(searchValue) > -1
            ),
          };
        });
        tempItemsContactsEmails.forEach((object) => {
          if (object.emails.length) {
            rightEmails.push(object);
          }
        });
        tempItemsContactsPhoneNumbers = tempItemsContactsPhoneNumbers.map(
          (element) => {
            return {
              ...element,
              phoneNumbers: element.phoneNumbers.filter(
                (item) =>
                  item.phoneNumber.toLowerCase().indexOf(searchValue) > -1
              ),
            };
          }
        );
        tempItemsContactsPhoneNumbers.forEach((object) => {
          if (object.phoneNumbers.length) {
            rightPhoneNumbers.push(object);
          }
        });
        tempItemsContactsOrganizations = tempItemsContactsOrganizations.map(
          (element) => {
            return {
              ...element,
              organizations: element.organizations.filter(
                (item) =>
                  item.organization.toLowerCase().indexOf(searchValue) > -1
              ),
            };
          }
        );
        tempItemsContactsOrganizations.forEach((object) => {
          if (object.organizations.length) {
            rightOrganizations.push(object);
          }
        });
        tempItemsCustomerNames = tempItemsCustomerNames.filter(
          (item) => item.customerName.toLowerCase().indexOf(searchValue) > -1
        );
        filteredItems = [
          ...new Set(
            tempItemsCustomerNames.concat(
              rightEmails,
              rightPhoneNumbers,
              rightOrganizations
            )
          ),
        ];
        // removing duplicates
        filteredArray = filteredItems.filter((obj) => {
          let isPresentInSet = uniqueValuesSet.has(obj._id);
          uniqueValuesSet.add(obj._id);
          return !isPresentInSet;
        });
        if (filteredArray.length) {
          return filteredArray;
        } else if (filteredArray.length === 0) {
          return filteredArray;
        }
      } else {
        return originalArr;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: block;
  padding: 24px 88px 72px 104px;
  min-height: 600px;
  background-color: #f4f5f7;
  overflow-x: scroll;
}
.customer-row {
  padding: 16px 24px;
  width: 700px;
  box-shadow: 0 0 2px rgba(28, 14, 3, 0.1);
  border-radius: 8px;
  margin-bottom: 4px;
  margin-top: 0 !important;
  background-color: #ffffff;
  cursor: pointer;
}
.button-col {
  display: flex;
  justify-content: flex-end;
  .v-btn {
    background-color: transparent !important;
    box-shadow: 0 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    min-width: 10px !important;
  }
}

.sector-type {
  width: 160px;
  max-width: 160px;
  height: 34px;
  max-height: 32px;
  font-size: 14px;
}
.client-type {
  width: 160px;
  max-width: 260px;
  margin-left: 8px;
  height: 34px;
  max-height: 32px;
  font-size: 14px;
}
::v-deep .v-select__selections {
  flex-wrap: nowrap;
}
.no-results-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 48px 48px 0;
  flex-grow: 0;
}
</style>
